/* Style for About.js */
.about_me{
    background-repeat: no-repeat;                
    background-position: 29% 53%; 
    background-size: 30%;
    display: flex;
    justify-content: space-evenly;
    margin: 10px 50px;
    position: relative;
}

.left_col,
.right_col {
  position: absolute;
  width: 35%;
  height: 90%;
  min-width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_col {
  left: 0;
  padding: 15px;
  align-items: flex-end;
}

.right_col {
  right: 0;
  padding: 20px;
  align-items: flex-start;
}

.picture_col{
    position: relative;
    margin-top: 200px;
    width: 30%;
    min-width: 400px;
    display: flex;
    justify-content: center;
}


.name_div{
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    line-height: 24px;
}

#hey{
    color: whitesmoke;
    font-family: Bubblegum Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 0px;
}

#name {
    color: var(--1, #EDB33C);
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 10px;
    flex-shrink: 0;
}
/* New Graduate*/
.status{
    color: #8DB6F4;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
    padding-right: 60px;
    padding-bottom: 30px;
}

.summary{
    color: whitesmoke;
    width: 90%;
    margin-left: 30px;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    flex-shrink: 0;
    margin-top: 35px;
}

.job {
    padding-left: 35px;
}

#software  {
    color: whitesmoke;
    font-family: Aclonica;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 138.462% */
}

#developer {
    color: #EDB33C;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 138.462% */
    padding-left: 90px;
    }


@media screen and (max-width: 1190px) {

    .about_me {
        flex-direction: column;
        align-items: center;
        background-position: 0px 210px;
        background-size: 50%;
      }
      
      .picture_col{
        margin-top: auto;
      }

      .summary{
        margin-top: 0px;
        height: 500px;
      }

      .job{
        display: none;
      }

      .left_col,
      .right_col,
      .picture_col {
        position: relative;
        width: auto;
        min-width: unset;
        
      }
    
      .left_col {
        left: unset;
        right: unset;
        align-items: center;
        padding-top: 0px;
      }
    
      .right_col {
        padding-left: 5px;
        padding-right: 5px;
        margin-top: auto;
        padding-top: 0px;
      }

      .status{
        display: none;
      }
    
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in forwards;
}
