.skill-container{
    display: flex;
    height: fit-content;
}

.skill_card_short{
    cursor: pointer;
    margin: 5px;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #8DB6F4;
    transition: all 250ms ease-in-out;
    background-color: transparent;
    height: fit-content;
    /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
}

.name{
    margin: 0;
    padding: 3px 10px;
    color: whitesmoke;
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}

.skill_card_short:hover{
    background-color: #8DB6F4;
}

.skill_card_short:hover p{
    color: #121212;
}


/* Expanded ------------------------------------------- */

.skill_card_details{
    
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin: 10px;
    background: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
    transition: all 150ms ease-in-out;
      
}

.skill_logo{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #00000036;
}
.skill_logo img {
    max-width: 90%;
    max-height: 90%;
  }

.info{
    display: flex;
    flex-direction: column;
    margin: 0px 24px 24px 24px;
    padding: 22px 0px 0px 0px;
    border-top: 2px solid #0000005f;
}

.heading{
    margin: 0;
    padding: 3px 22px;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    align-self: right;
    color: whitesmoke;
    margin: 10px 0px;
    padding-bottom: 0px;
}

.projects{
    margin-bottom: 16px;
}

.span_heading{
    font-size: 18px;
    line-height: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: whitesmoke;
}



.projects ul,
.courses ul{
    list-style-type: none;
    margin: 10px;
    padding: 0;
}

.projects li,
.courses li{
    float: left;
    border: 2px solid whitesmoke;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 100px;
    margin-top: 7px;
    transition: all 150ms ease-in-out;

}


.span_name{
    font-weight: 300;
    color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    cursor: default;
}

