.project-cards{
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    margin-left: 80px;
}

.projects-section{
    margin: 70px 50px;
}

.projects-section h1{
    margin-left: 75px;
    color: #EDB33C;
    font-family: Aclonica;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
}

@media screen and (max-width: 1190px) {
    .project-cards {
      margin-left: 15px; /* Adjust margin for smaller screens */
    }

    .projects-section{
        margin: 50px 15px;
    }
  
    .projects-section h1 {
      margin-left: 10px; /* Adjust margin for smaller screens */
      font-size: 36px;
    }
  }