.main{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.skills_div{
    border: 2px solid white;
    margin-top: 100px;
}

.horizontal-line {
    background: linear-gradient(to right, transparent, #ffffff, transparent);
    height: 1px;
    width: 100%;
  }