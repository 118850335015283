.admin_skills, .admin_projects{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skills-list, .projects-list{
    height: 600px;
    overflow: scroll;
}

.admin-main{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    
}

.admin_skills h1, .admin_projects h1, .admin_resume{
    color: #EDB33C;
}
