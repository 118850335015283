.cv_button {
    background-color: #EDB33C;
    font-family: 'Poppins', sans-serif;
    border: none;
    color: #121212;
    font-weight: bold;
    padding: 8px 12px;
    font-size: medium;
    border-radius: 8px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: all 150ms ease-in-out;
    white-space: nowrap;
}

.cv_button:hover {
    transform: scale(1.05); /* Increase size by 5% */
    background-color: transparent; /* Keep background transparent on hover */
    border: 2px solid #EDB33C; /* Green border on hover */
    color: #EDB33C; /* Green text on hover */
}
