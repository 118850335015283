@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.container {
    max-width: fit-content;
    margin: 0 auto;
    padding: 30px 15px;
    
}

.card {
    position: relative;
    width: 280px;
    height: 370px;
    margin: 0 auto;
    background: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
    border-radius: 15px;
    box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);

    .face {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.face1 {
            box-sizing: border-box;
            padding: 0px 5px 0px 5px;
            height: 100%;

            .content{
              padding-top: 60px;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            .description {
                font-family: Poppins;
                margin-left: 10px;
                background-color: #f5f7fa;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .tech {
                font-family: Poppins;
                font-size: 15px;
                background-color: #f5f7fa;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .link {
              position: absolute;
              bottom: 10px;
              right: 10px;
              cursor: pointer;
            }
            
        }

            &.face2 {
                transition: 0.5s;
                top: 0%;
    
                h2 {
                    font-family: Aclonica;
                    max-width: 100%;
                    max-height: 100%;
                    text-align: center;
                    text-wrap: wrap;
                    word-wrap: break-word;
                    margin: 0;
                    padding: 0;
                    font-size: 36px;
                    line-height: 56px;
                    color: #8DB6F4;
                    transition: all 0.5s ease-in-out;
                    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                    z-index: 10;
                }
          }
        }
    }
    .content{
        height: 100%;
        top: 0%;
        overflow: hidden;
    }

    .content ul {
        list-style: none;
        padding: 0;
      }
      
      .content li {
        border: 2px solid #8DB6F4;
        border-radius: 100px;
        padding: 5px 10px;
        float: left;
        margin: 5px;
      }

      .card:hover .face.face2 {
        height: 60px;
  
        h2 {
          font-size: 21px;
          line-height: normal;
        }
      }

      .card .face.face2 {
        background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #383838;
        background-blend-mode: multiply, multiply;    

        border-radius: 15px;
      }