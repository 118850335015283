/* Required styles */
.portrait_container {
  width: 100%;
}


.image {
    transition: transform 0.2s ease-in;
  }

  .image__foreground {
    transform-origin: 50% 50%;
    transform: translateY(4px) scale(1, 1);
    transition: transform 0.2s ease-in;
  }
  
  .image:hover .image__foreground {
    transform: translateY(-7px) scale(1.05, 1.05);
  }

  @media screen and (max-width: 1190px) {
    .image {
      transform: scale(0.9, 0.9);
    }
  }