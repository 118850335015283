/* Header CSS */
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 150ms ease-in-out;
    margin: 0px 150px;
}

.header .nav-div ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .header .nav-div li {
    font-family: 'Poppins', sans-serif;
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .header li:hover {
    transform: scale(1.2);
    font-weight: bold;
  }

  .header .nav-div a {
    text-decoration: none;
    color: whitesmoke;
  }

  .logo {
    display: flex;
    font-size: xx-large;
    font-family: 'Montserrat', sans-serif;
  }

  #B {
    font-weight: bold;
    color: #EDB33C;
  }

  #Padda {
    font-weight: lighter;
    color: whitesmoke;
  }

  .nav-div{
    display: flex;
    margin-left: 40px;
    width: 50%;
    justify-content: center;
  }

  .admin{
    margin-left: 0px;
    cursor: pointer;
    transition: all 150ms ease-in-out;
  }

  .admin:hover{
    transform: scale(1.2);
  }

  .admin-cv{
    display: flex;
    width: 18%;
    justify-content: space-between;
    align-items: center;
    transition: all 250ms ;
  }

  .menu{
    display: none;
  }

  @media screen and (max-width: 1190px){
    .menu{
      display: block;
    }

    .cv_button_div{
      display: none;
    }

    .admin-cv{
      width: 80px;
      margin-right: 10px;
    }

    .nav-div{
      display: none;
    }

    .header{
      margin: 0 auto;
    }

    .logo{
      margin-left: 10px;
    }
    
  }