.skills_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%; /* Adjust width to your desired ratio */
  margin-top: 20px;
}

.skills_category {
  flex-shrink: 0; /* Prevent categories from shrinking */
  width: 600px; /* Adjust width as needed */
  padding-top: 20px;
  margin-left: 30px;
}

.skills_category .category_heading {
  margin-left: 75px;
  color: whitesmoke;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.skills_list {
  display: flex;
  flex-direction: row;
  margin: 5px 80px;
  border-radius: 8px;
  transition: all 150ms ease-in-out;
  flex-wrap: wrap;
}

.skills_section {
  margin: 100px 50px;
  padding: 10px 0px;
}

.skills_section .section_heading {
  margin-left: 75px;
  color: #EDB33C;
  font-family: Aclonica;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 66px; /* 138.462% */
}

@media screen and (max-width: 1190px) {
  .skills_container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }
  
  .skills_section{
    margin: 5px 15px;
    padding: 0px 5px;
  }

  .skills_section .section_heading{
    margin-left: 0;
    font-size: 36px;
  }

  .column-wrapper,
  .skills_category,
  .skills_category .category_heading,
  .skills_list {
    width: 100%; /* Full width for smaller screens */
    margin-left: 0; /* Reset left margin */
  }
}