@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@700&display=swap');


.footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
}

.links-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 20px;

    h1{
        color: rgba(245, 245, 245, 0.891);
        font-family: Gowun Batang;
        font-size: 66px;
        font-style: bold;
        font-weight: 700;
        line-height: 66px; /* 138.462% */
    }

}

.contact-phone, .contact-email, .linkedin, .github{
    color: rgba(245, 245, 245, 0.891);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    span{
        margin-left: 10px;
    }
}

.contact-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.link-icons{
    display: flex;
    justify-content: space-around;
    width: 70%;
    color: whitesmoke;
}

.contact-form{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .name-email{
        display: flex;
        gap: 20px;
        justify-content: space-between;
        height: 40px;

        input{
            width: 49%;
            height: 100%;
            font-size: 20px;
            padding-left: 10px;
            border-radius: 8px;
            border: 2px solid #8DB6F4;
            background: transparent;
            color: rgba(245, 245, 245, 0.891);
        }
    }

    input::placeholder{
        color: whitesmoke;
    }

    textarea{
        font-size: 20px;
        padding: 10px;
        border-radius: 8px;
        border: none;
        border: 2px solid #8DB6F4;
        background: transparent;
        color: rgba(245, 245, 245, 0.891);
    }

    textarea::placeholder{
        color: whitesmoke;
    }
}

.send-message{
    width: 97%;
    align-self: center;
    font-size: 20px;
    padding: 10px 20px;
    font-family: Poppins;
    background-color: #EDB33C;
    color: black;
    border-radius: 8px;
    transition: 200ms ease-in-out;
}

.send-message:hover {
    transform: scale(1.03);
    background-color: transparent; 
    border: 2px solid #EDB33C;
    color: #EDB33C;
}

@media screen and (max-width: 1190px) {
    .footer {
      padding: 10px;
      flex-direction: column;
    }
  
    .links-section {
      text-align: center;
      margin: 20px;
    }

    .contact-form {
        width: 100%; /* Full width on smaller screens */
        max-width: 400px; /* Limit the maximum width for better responsiveness */
        margin: 0 auto; /* Center the form horizontally */
      }

      .name-email{
        margin: 10px 10px;
      }
    
      textarea {
        font-size: 10px;
        width: 90%;
        align-self: center;
      }
    
      .send-message {
        width: 100%; /* Full width on smaller screens */
      }
  }
