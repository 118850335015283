.sidebar{
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    z-index: 1;
}

.side-nav{
    height: 100%;
}

.side-nav ul{
    height: 50%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
}

.side-nav a{
    margin-right: 20px;
    font-family: 'Poppins', sans-serif;
    align-self: center;
    text-decoration: none;
    color: white;
}

.close-side{
    position: absolute;
    top: 10px;
    right: 10px;
}